import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DurationCallender from "../../components/Dashboard/DurationCalender";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import Colors from "../../constant/Colors";
import { useTranslation } from "react-i18next";
import { store } from "../../App";
import { updateCurrencyUnit, } from "../../store/actions/analyticsAction";
import dayjs from "dayjs";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import PrintMonthlyReportData from "./PrintMonthlyReportData";
import { ActivityIndicator } from "react-native-web";
import i18n from "../../i18n";

import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";
import { SiMicrosoftexcel } from "react-icons/si";
import { downloadExcel } from "react-export-table-to-excel";
import { IoPrintOutline } from "react-icons/io5";

const MonthlyReportScreen = ({ }) => {
  const { centerId } = useLocation().state;
  const duration = useSelector((state) => state.analytics.duration);

  let lastDollarPrices = store.getState().dollarPrices.dollarPrices
  let [dollarPrice, setDollarPrice] = useState()

  const language = i18n.language

  const currency = useSelector((state) => state.analytics.currency);

  const { data, isLoading, error } = useSwr(`/analysis/centers?centerId=${centerId}&start_date=${dayjs(duration.startDate).format('YYYY-MM-DD')}&end_date=${dayjs(duration.endDate).format('YYYY-MM-DD')}`, fetcher)
  const { data: expenses, isLoading: expensesLoading } = useSwr(`/expenses?centerId=${centerId}&startDate=${dayjs(duration.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(duration.endDate).format('YYYY-MM-DD')}`, fetcher)
  console.log({ currency })
  // useEffect(() => {
  //   if (currency === 'USD') {
  //     store.dispatch(updateCurrencyUnit(1))
  //   }
  // }, [currency])

  useEffect(() => {
    const dollar = lastDollarPrices?.find(a => a.type === 1 && a.centers.includes(centerId) && dayjs(a.date).isSame(dayjs(duration.date || duration.startDate), 'month') && dayjs(a.date).isSame(dayjs(duration.date || duration.startDate), 'year'))
    setDollarPrice(dollar?.price)
  }, [duration])

  if (error) return <Error />


  if (isLoading || expensesLoading) return <Loading />
  // console.log(duration)
  return (
    <div className="flex flex-col flex-1 bg-[#f1f1f1] h-full">
      {duration && <DurationCallender duration={duration} />}

      {data && expenses && <div style={{ direction: language === 'en' ? 'ltr' : 'rtl', zIndex: 10 }} className="flex flex-col flex-wrap items-center w-full mt-12 justify-evenly">
        <Table expenses={expenses} currency={currency} data={data} dollarPrice={dollarPrice} />
        <CoursesReportData currency={currency} data={data[0].akafitness.data[0].data.revenue.courses} />
        <SupplementsReportData currency={currency} data={data[0].akafitness.data[0].data.revenue.supplements} />
        {/* <ReceptionsReportData currency={currency} data={data[0].akafitness.data[0].data.revenue.memberships.receptions} /> */}
        <SalaryReportData courses={data[0].akafitness.data[0].data.revenue.courses} receptions={data[0].akafitness.data[0].data.revenue.memberships.receptions} supplements={data[0].akafitness.data[0].data.revenue.supplements} data={data[0].akafitness.data[0].data.salaries} currency={currency} />
        <CafeItemsReport centerId={centerId} duration={duration} />
        <ExpenseData currency={currency} expensesData={expenses} />
        <ExpenseByCategoryData currency={currency} duration={duration} />
      </div>}
    </div>
  );
};

const PrintButton = ({ data, dollarPrice, expenses }) => {
  const componentRef = useRef();

  return (
    <div className="mx-2 mb-4" >

      <div style={{ display: "none" }}>
        <PrintMonthlyReportData
          ref={componentRef}
          dollarPrice={dollarPrice}
          data={data}
          expenses={expenses}
        />
      </div>
      <ReactToPrint trigger={PrintBtn} content={() => componentRef.current} />


    </div>)
}

const PrintBtn = () => <IoPrintOutline className="cursor-pointer" size={28} color="green" />


export const Table = ({ data, expenses, isFromPrint, dollarPrice, currency }) => {
  const centerName = data[0]?.akafitness?.data[0]?.centerName

  const revenue = data[0]?.akafitness?.data[0].data.revenue
  const { gymExpenses, cafeteriaExpenses, bigExpenses } = data[0]?.akafitness?.data[0].data.expenses
  const totalSalary = currency === 'IQD' ? data[0]?.akafitness?.data[0].data.salaries.totalSalary : data[0]?.akafitness?.data[0].data.salaries.totalSalaryUsd
  const totalSupplementRevenue = revenue.supplements.prices.revenue[currency?.toLowerCase()] || 0
  // console.log(data[0]?.akafitness?.data[0].data.salaries, currency, totalSalary)
  const totalSupplementExpenses = revenue.supplements.prices.expenses[currency?.toLowerCase()]
  const totalSwimmingItemsRevenue = revenue.swimmingItems.prices.revenue[currency?.toLowerCase()]
  const totalProfit = ((revenue.memberships.prices.revenue[currency?.toLowerCase()] - revenue.memberships.prices.expenses[currency?.toLowerCase()]) +
    totalSwimmingItemsRevenue +
    (totalSupplementRevenue - totalSupplementExpenses) +
    (revenue.courses.prices.revenue[currency?.toLowerCase()] - revenue.courses.prices.expenses[currency?.toLowerCase()]) +
    (revenue.cafeteria.prices.revenue[currency?.toLowerCase()] - cafeteriaExpenses[currency?.toLowerCase()])) -
    (gymExpenses[currency?.toLowerCase()] + bigExpenses[currency?.toLowerCase()] + totalSalary)

  // console.log({revenue})

  const Indexes = [{
    label: 'totalMembershipsRevenue',
    value: revenue.memberships.prices.revenue[currency?.toLowerCase()],
    color: '#000'
  }, {
    label: 'totalNormalCoursesRevenue',
    value: revenue.courses.data.reduce((a, b) => a + b.totalNormalCourse[currency?.toLowerCase()], 0),
    color: '#000'
  }, {
    label: 'totalPTCoursesRevenue',
    value: revenue.courses.data.reduce((a, b) => a + b.totalVIPCourse[currency?.toLowerCase()], 0),
    color: '#000'
  },
  {
    label: 'totalCafeteriaRevenue',
    value: revenue.cafeteria.cafeteria.totalPriceOfBalance[currency?.toLowerCase()] +
      revenue.cafeteria.cafeteria.totalPriceOfCash[currency?.toLowerCase()] +
      revenue.cafeteria.cafeteria.totalPriceOfCard[currency?.toLowerCase()],
    color: '#000'
  },
  {
    label: 'physicalTherapyRevenue',
    value: revenue.cafeteria.other.physicalTherapy[currency?.toLowerCase()],
    color: '#000',
  },
  {
    label: 'supplementsRevenue',
    value: totalSupplementRevenue,
    color: '#000',
  },
  {
    label: 'swimmingItemsRevenue',
    value: totalSwimmingItemsRevenue,
    color: '#000',
  },
  {
    label: 'totalRevenue',
    value: revenue.cafeteria.other.garage[currency?.toLowerCase()] + totalSwimmingItemsRevenue + totalSupplementRevenue + revenue.cafeteria.other.physicalTherapy[currency?.toLowerCase()] + revenue.cafeteria.other.salon[currency?.toLowerCase()] + revenue.cafeteria.other.vipLocker[currency?.toLowerCase()] + revenue.cafeteria.cafeteria.totalPriceOfBalance[currency?.toLowerCase()] + revenue.cafeteria.cafeteria.totalPriceOfCash[currency?.toLowerCase()] + revenue.cafeteria.cafeteria.totalPriceOfCard[currency?.toLowerCase()] + revenue.courses.data.reduce((a, b) => a + b.totalVIPCourse[currency?.toLowerCase()], 0) + revenue.courses.data.reduce((a, b) => a + b.totalNormalCourse[currency?.toLowerCase()], 0) + revenue.memberships.prices.revenue[currency?.toLowerCase()],
    color: '#000',
    isBold: true
  },
  {
    label: 'totalCafeteriaExpenses',
    value: cafeteriaExpenses[currency?.toLowerCase()],
    color: Colors.mainRedColor
  },
  {
    label: 'totalGymExpenses',
    value: gymExpenses[currency?.toLowerCase()],
    color: Colors.mainRedColor
  },
  {
    label: 'totalBigExpenses',
    value: bigExpenses[currency?.toLowerCase()],
    color: Colors.mainRedColor
  },
  {
    label: 'totalReceptionRatio',
    value: revenue.memberships.prices.expenses[currency?.toLowerCase()],
    color: Colors.mainRedColor
  },
  {
    label: 'totalTrainerRatioOfSupplements',
    value: totalSupplementExpenses,
    color: Colors.mainRedColor
  },
  {
    label: 'totalFixedSalary',
    value: totalSalary,
    color: Colors.mainRedColor
  },
  {
    label: 'totalExpenses',
    value: totalSalary + totalSupplementExpenses + revenue.memberships.prices.expenses[currency?.toLowerCase()] + bigExpenses[currency?.toLowerCase()] + gymExpenses[currency?.toLowerCase()] + cafeteriaExpenses[currency?.toLowerCase()],
    color: Colors.mainRedColor,
    isBold: true
  },
  {
    label: 'totalMembershipsProfit',
    value: revenue.memberships.prices.revenue[currency?.toLowerCase()] - revenue.memberships.prices.expenses[currency?.toLowerCase()],
    color: Colors.mainAqwaBlue
  },
  {
    label: 'totalCoursesProfit',
    value: revenue.courses.prices.revenue[currency?.toLowerCase()] - revenue.courses.prices.expenses[currency?.toLowerCase()],
    color: Colors.mainAqwaBlue
  },
  {
    label: 'totalCafeteriaProfit',
    value: revenue.cafeteria.prices.revenue[currency?.toLowerCase()] - cafeteriaExpenses[currency?.toLowerCase()] - (revenue.cafeteria.other.garage[currency?.toLowerCase()] + revenue.cafeteria.other.physicalTherapy[currency?.toLowerCase()] + revenue.cafeteria.other.salon[currency?.toLowerCase()] + revenue.cafeteria.other.vipLocker[currency?.toLowerCase()]),
    color: Colors.mainAqwaBlue
  },
  {
    label: 'physicalTherapyProfit',
    value: revenue.cafeteria.other.physicalTherapy[currency?.toLowerCase()],
    color: Colors.mainAqwaBlue
  },
  {
    label: 'supplementsProfit',
    value: totalSupplementRevenue - totalSupplementExpenses,
    color: Colors.mainAqwaBlue
  },
  {
    label: 'swimmingItemsProfit',
    value: totalSwimmingItemsRevenue,
    color: Colors.mainAqwaBlue
  },
  {
    label: 'totalProfit',
    // value: showInIQD(totalProfit, 'IQD',) + ' - ' + showInIQD(totalProfit / ((dollarPrice + '').substring(0, 4)), 'USD'),
    value: currency === 'IQD' ? showInIQD(totalProfit, 'IQD',) : showInIQD(totalProfit, 'USD',),
    color: 'green',
    desc: `(${t('totalMembershipsProfit')}+${t('totalSwimmingItemsRevenue')}+${t('totalCoursesProfit')}+${t('totalSupplementsProfit')}+${t('totalPhysicalTherapyProfit')}+${t('totalCafeteriaProfit')})-(${t('totalBigExpenses')}+${t('totalFixedSalary')}+${t('totalGymExpenses')})`,
    notShowAsCurrency: true,
    isBold: true
  },]

  return (
    <div
      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        zIndex: 10
      }}
    >
      <div className="flex items-center justify-between " >
        <div>
          <h1 className="mb-4 text-lg " >{`${t(centerName)}`} ( {t('theDollarIsGenerallyCalculatedAt') + ' ' + (dollarPrice || 0)} { } )</h1>
        </div>

        <div className="flex ">
          {/* {centerId !== 'total' && userId === '62d94f8cb01bd7633a9cea96' && <SaveButton userId={userId} onClickSave={onClickSave} />} */}
          {!isFromPrint && <PrintButton expenses={expenses} dollarPrice={dollarPrice} data={data} />}
        </div>
      </div>
      {Indexes &&
        Indexes.map((dt, i) => {
          return <Index key={i} currency={currency} desc={dt.desc} notShowAsCurrency={dt.notShowAsCurrency} isBold={dt.isBold} label={dt.label} color={dt.color} value={dt.value} />;
        })}
    </div>
  );
};

const Index = ({ label, value, color, currency, notShowAsCurrency, desc, isBold }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: "row",
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginBottom: 10,
      }}
    >
      <div>
        <h1 style={{ fontSize: 18, color: color || null, fontWeight: isBold ? 'bold' : 'normal' }}>{t(label)}</h1>
        {desc && <h5 className="text-black opacity-70 w-[80%] text-sm" >{desc}</h5>}
      </div>
      <h1 style={{ color: color || null, fontFamily: 'Montserrat', fontWeight: isBold ? 'bold' : 'normal' }}>{notShowAsCurrency ? value : showInIQD(value, currency)}</h1>
    </div>
  );
};


const courseLabels = ['trainer', 'numOfNormalCourses', 'numOfVIPCourses', 'totalPriceOfRegularCourses', 'totalPriceOfVIPCourses', 'trainerProfitsFromRegularCourses', 'trainerProfitsFromVIPCourses', 'akaFitnessProfitFromRegularCourses', 'akaFitnessProfitsFromVIPCourses', 'coachGrossProfit', 'grossProfitAkaFitness']
export const CoursesReportData = ({ data, isFromPrint, currency }) => {
  return (
    <div
      className="my-8"
      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <h1 className="mb-4 text-lg " >{t('courseReportSummary')}</h1>

      <CourseTable labels={courseLabels} >
        {data.data.map((dt, index) => {
          console.log({ dt })
          if (dt.numOfNormalCourse === 0 && dt.numOfVIPCourse === 0) return null
          return <ReactToPrint trigger={() => <tr style={{ cursor: 'pointer' }} key={index} >
            <td>{dt.trainerName}</td>
            <td>{dt.numOfNormalCourse}</td>
            <td>{dt.numOfVIPCourse}</td>
            <td>{dt.totalNormalCourse[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalVIPCourse[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalNormalCourseWithOurRatio[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalVIPCourseWithOurRatio[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{(dt.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()] + dt.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()]).toLocaleString()}</td>
            <td>{(dt.totalVIPCourseWithOurRatio[currency?.toLowerCase()] + dt.totalNormalCourseWithOurRatio[currency?.toLowerCase()]).toLocaleString()}</td>
          </tr>}
          />
        })}
        {/* Total */}
        <tr>
          <td>{t('total')}</td>
          <td>{data.data.reduce((acc, data) => acc + data.numOfNormalCourse, 0)}</td>
          <td>{data.data.reduce((acc, data) => acc + data.numOfVIPCourse, 0)}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalNormalCourse[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalVIPCourse[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalNormalCourseWithOurRatio[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalVIPCourseWithOurRatio[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()] + data.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()], 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => acc + data.totalVIPCourseWithOurRatio[currency?.toLowerCase()] + data.totalNormalCourseWithOurRatio[currency?.toLowerCase()], 0).toLocaleString()}</td>
        </tr>
      </CourseTable>
    </div>

  );
};

const receptionsLabels = ['reception', 'numOfThreeMonth', 'numOfSixMonth', 'totalPriceOfThreeMonths', 'totalPriceOfSixMonths', 'receptionRatioOfThreeMonths', 'receptionRatioOfSixMonths', 'receptionDivideBenefitIQD', 'akaFitnessDivideBenefitIQD']
export const ReceptionsReportData = ({ data, isFromPrint, currency }) => {
  // receptionsReportData?.sort((a, b) => b.totalMemberships - a.totalMemberships)
  return (
    <div
      className="my-8"

      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <h1 className="mb-4 text-lg " >{t('receptionReportSummary')}</h1>
      <div style={{ display: "none" }}>

      </div>
      <CourseTable labels={receptionsLabels} >
        {data.map((dt, index) => {
          if (dt.numOfThreeMonthMemberships === 0 && dt.numOfSixMonthMemberships === 0) return null
          return <ReactToPrint trigger={() => <tr style={{ cursor: 'pointer', }} key={index} >
            <td>{dt.name}</td>

            <td>{dt.numOfThreeMonthMemberships}</td>
            <td>{dt.numOfSixMonthMemberships}</td>
            <td>{dt.totalPriceOfThreeMonthMemberships}</td>
            <td>{dt.totalPriceOfSixMonthMemberships}</td>
            <td>{dt.receptionRatioOfThreeMonthMemberships}</td>
            <td>{dt.receptionRatioOfSixMonthMemberships}</td>
            <td>{dt.receptionDivideBenefitIQD.toLocaleString()}</td>
            <td>{dt.akaFitnessDivideBenefitIQD.toLocaleString()}</td>
          </tr>}
          />
        })}
        <tr>
          <td>{t('total')}</td>
          <td>{data.reduce((acc, dt) => acc + dt.numOfThreeMonthMemberships, 0)}</td>
          <td>{data.reduce((acc, dt) => acc + dt.numOfSixMonthMemberships, 0)}</td>
          <td>{data.reduce((acc, dt) => acc + dt.totalPriceOfThreeMonthMemberships, 0)}</td>
          <td>{data.reduce((acc, dt) => acc + dt.totalPriceOfSixMonthMemberships, 0)}</td>
          <td>{data.reduce((acc, dt) => acc + dt.receptionRatioOfThreeMonthMemberships, 0)}</td>
          <td>{data.reduce((acc, dt) => acc + dt.receptionRatioOfSixMonthMemberships, 0)}</td>
          <td>{data.reduce((acc, dt) => acc + dt.receptionDivideBenefitIQD, 0).toLocaleString()}</td>
          <td>{data.reduce((acc, dt) => acc + dt.akaFitnessDivideBenefitIQD, 0).toLocaleString()}</td>
        </tr>
      </CourseTable>
    </div>

  );
};

const SalaryLabels = ['num', 'nameOfTheEmployee', 'position', 'fixedSalary', 'normalCourseRatio', 'PTCourseRatio', 'supplementRatio', 'membershipsRatio', 'totalPrice']
export const SalaryReportData = ({ data, courses, receptions, supplements, isFromPrint, currency }) => {
  return (
    <div
      className="my-8"

      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <h1 className="mb-4 text-lg " >{t('salariesOfEmployees', { price: null })}</h1>
      <CourseTable labels={SalaryLabels} >
        {data.employees.filter(a => a.salary !== 0).map((dt, index) => {
          if (dt.salary === 0) return null
          if (!dt.totalMembershipRatioProfit) {
            dt.totalMembershipRatioProfit = 0
          }
          const course = courses.data.find(a => a.trainerId === dt.id)
          const reception = receptions.find(a => a._id === dt.id)
          const supplement = supplements.data.find(a => a.trainerId === dt.id)

          return <tr key={index} >
            <td>{index + 1}</td>
            <td>{dt.name}</td>
            <td>{t(dt.type)}</td>
            <td>{currency === 'IQD' ? dt.salary.toLocaleString() : (dt.salaryUsd || 0).toLocaleString()}</td>
            <td>{!course ? 0 : (course.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()] || 0)?.toLocaleString()}</td>
            <td>{!course ? 0 : (course.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()] || 0)?.toLocaleString()}</td>
            <td>{!supplement ? 0 : (supplement.totalSellSupplementsTrainerRatio[currency?.toLowerCase()] || 0)?.toLocaleString()}</td>
            <td>{!reception ? 0 : (reception.receptionDivideBenefitIQD || 0)?.toLocaleString()}</td>
            <td>{((reception?.receptionDivideBenefitIQD || 0) + (supplement?.totalSellSupplementsTrainerRatio[currency?.toLowerCase()] || 0) + (currency === 'IQD' ? dt.salary : (dt.salaryUsd || 0)) + (course ? (course.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()] || 0) + course.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()] || 0 : 0)).toLocaleString()}</td></tr>
        })}
        <tr>
          <td>#</td>
          <td>{t('total')}</td>
          <td>-</td>
          <td>{data.employees.reduce(((acc, { salary, salaryUsd }) => acc + (currency === 'IQD' ? salary : (salaryUsd || 0))), 0).toLocaleString()}</td>
          <td>{data.employees.reduce((acc, dt) => (acc + ((courses.data.find(a => a.trainerId === dt.id)?.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()] || 0))), 0).toLocaleString()}</td>
          <td>{data.employees.reduce((acc, dt) => (acc + (courses.data.find(a => a.trainerId === dt.id)?.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()] || 0)), 0).toLocaleString()}</td>
          <td>{data.employees.reduce((acc, dt) => (acc + ((supplements.data.find(a => a.trainerId === dt.id)?.totalSellSupplementsTrainerRatio[currency?.toLowerCase()] || 0))), 0).toLocaleString()}</td>
          <td>{data.employees.reduce((acc, dt) => (acc + ((receptions.find(a => a._id === dt.id)?.receptionDivideBenefitIQD || 0))), 0).toLocaleString()}</td>
          <td>{data.employees.reduce((acc, dt) => (acc + (((courses.data.find(a => a.trainerId === dt.id)?.totalNormalCourseWithTrainerRatio[currency?.toLowerCase()] || 0) + courses.data.find(a => a.trainerId === dt.id)?.totalVIPCourseWithTrainerRatio[currency?.toLowerCase()] || 0) + (supplements.data.find(a => a.trainerId === dt.id)?.totalSellSupplementsTrainerRatio[currency?.toLowerCase()] || 0) + (receptions.find(a => a._id === dt.id)?.receptionDivideBenefitIQD || 0) + (currency === 'IQD' ? dt.salary : (dt.salaryUsd || 0)))), 0).toLocaleString()}</td>
        </tr>
      </CourseTable>
    </div>
  );
};
const SupplementsLabels = ['trainer', 'numOfSupplements', 'totalPriceOfSupplements', 'trainerProfit15%', 'grossProfitAkaFitness']
export const SupplementsReportData = ({ data, isFromPrint, currency }) => {

  return (
    <div
      className="my-8"

      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <h1 className="mb-4 text-lg " >{t('summaryOfTheDietarySupplementReport')}</h1>

      <CourseTable labels={SupplementsLabels} >
        {data.data.map((dt, index) => {
          if (dt.numOfSellSupplements === 0) return null
          return <ReactToPrint trigger={() => <tr style={{ cursor: 'pointer' }} key={index} >
            <td>{dt.trainerName}</td>
            <td>{dt.numOfSellSupplements}</td>
            <td>{dt.totalSellSupplements[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalSellSupplementsTrainerRatio[currency?.toLowerCase()]?.toLocaleString()}</td>
            <td>{dt.totalSellSupplementsOurRatio[currency?.toLowerCase()]?.toLocaleString()}</td>
          </tr>} />
        })}
        <tr>
          <td>{t('total')}</td>
          <td>{data.data.reduce((acc, data) => (acc + data.numOfSellSupplements), 0)}</td>
          <td>{data.data.reduce((acc, data) => (acc + data.totalSellSupplements[currency?.toLowerCase()]), 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => (acc + data.totalSellSupplementsTrainerRatio[currency?.toLowerCase()]), 0).toLocaleString()}</td>
          <td>{data.data.reduce((acc, data) => (acc + data.totalSellSupplementsOurRatio[currency?.toLowerCase()]), 0).toLocaleString()}</td>
        </tr>
      </CourseTable>
    </div>
  );
};

export const CourseTable = ({ labels, isLoading, children, currency }) => {
  return <table className="my-table">
    <TableHeader labels={labels} />
    <tbody className={`${isLoading ? 'animate-pulse' : ''}`} >
      {children}
      {isLoading && <tr>
        {labels.map((a, i) => <td key={i} className="w-[320px] m-1 h-[120px] bg-gray-300" />)}
      </tr>}
      {isLoading && <tr>
        {labels.map((a, i) => <td key={i} className="w-[320px] m-1 h-[120px] bg-gray-300" />)}
      </tr>}
    </tbody>
  </table>
}

const TableHeader = ({ labels }) => {
  return <thead>
    <tr>
      {labels.map((label, index) => <th key={index}>{t(label)}</th>)}
    </tr>
  </thead>
}

const CafeItemsLabels = ['name', 'price', 'qty', 'totalPrice']
export const CafeItemsReport = ({ duration, centerId, isFromPrint }) => {
  const { data, isLoading, error } = useSwr(`/purchases-activity/purchases-report/items?centerId=${centerId}&startDate=${dayjs(duration.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(duration.endDate).format('YYYY-MM-DD')}`, fetcher)

  const OnClickExportExcel = () => {
    downloadExcel({
      fileName: "cafeteria",
      sheet: 'cafeteria',
      tablePayload: {
        header: ['Name', 'Price', 'QTY.', 'Total Price'],
        body: data.data.map((item) => {
          const newDt = {}
          newDt['name'] = item.name
          newDt['price'] = item.normalPrice
          newDt['qty'] = item.totalSale
          newDt['totalPrice'] = item.totalPrice
          return newDt
        }),
      },
    });
  }

  return (
    <div
      className="my-8"
      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <div className="flex items-center justify-between" >
        <h1 className="mb-4 text-lg " >{t('cafeteriaSalesReportSummary')}</h1>
        {!isFromPrint && <div className='cursor-pointer' onClick={OnClickExportExcel} >
          <SiMicrosoftexcel size={24} color='green' />
        </div>}
      </div>
      <div>
      </div>
      {(data && data.data) ? <CourseTable labels={CafeItemsLabels} >
        {data.data.map((dt, index) => {
          return <tr key={index} >
            <td>{dt.name}</td>
            <td>{dt.normalPrice}</td>
            <td>{(dt.totalSale + dt.totalSaleByFree + dt.totalSaleWithDiscount)} </td>
            <td>{(dt.totalPrice + (dt.totalPriceWithDiscount - (dt.totalPriceWithDiscount * 0.1))).toLocaleString()}</td>
          </tr>
        })}
        {/* total */}
        <tr>
          <td>{t('total')}</td>
          <td>-</td>
          <td>{data?.data.reduce((acc, dt) => acc + (dt.totalSale + dt.totalSaleByFree + dt.totalSaleWithDiscount), 0)}</td>
          <td>{data?.data.reduce((acc, dt) => acc + (dt.totalPrice + (dt.totalPriceWithDiscount - (dt.totalPriceWithDiscount * 0.1))), 0).toLocaleString()}</td>
        </tr>
      </CourseTable> : <ActivityIndicator size='large' />}
    </div>)
}

const expensesLabel = ['name', 'category', 'price']
export const ExpenseData = ({ expensesData, isFromPrint, currency }) => {
  const language = i18n.language
  expensesData?.sort((a, b) => b[currency?.toLowerCase()] - a[currency?.toLowerCase()])

  const OnClickExportExcel = () => {
    downloadExcel({
      fileName: "expenses",
      sheet: 'expenses',
      tablePayload: {
        header: expensesLabel,
        body: expensesData.map((item) => {
          const newDt = {}
          newDt['name'] = item.name
          newDt['price'] = item[currency?.toLowerCase()]
          newDt['category'] = item.expenseCategory ? item.expenseCategory[language] : ' - '
          newDt['date'] = dayjs(item.createdAt).format('YYYY-MM-DD hh:mm')
          return newDt
        }),
      },
    });
  }
  return (
    <div
      className="my-8"

      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <div className="flex items-center justify-between" >
        {<h4 className="mb-4 text-lg ">{t('expenses')}</h4>}
        {!isFromPrint && <div className='cursor-pointer' onClick={OnClickExportExcel} >
          <SiMicrosoftexcel size={28} color='green' />
        </div>}
      </div>
      {<CourseTable labels={expensesLabel} >
        {expensesData && expensesData.map((dt, index) => {
          return <tr key={index} >
            <td>{dt.name} - <span className="text-red-600" >{dt.type === 1 ? t('gymExpense') : dt.type === 2 ? t('cafeExpense') : t('bigExpense')}</span></td>
            <td>{dt.expenseCategory ? dt.expenseCategory[language] : ' - '}</td>
            <td>{dt.iqd.toLocaleString()}</td>
          </tr>
        })}
      </CourseTable>}
    </div>)
}

const ExpenseByCategoryLabels = ['category', "totalIqd", 'totalUsd']
export const ExpenseByCategoryData = ({ duration, isFromPrint, currency }) => {
  const language = i18n.language
  const { data, isLoading, error } = useSwr(`/expenses/by-category/totals?startDate=${dayjs(duration.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(duration.endDate).format('YYYY-MM-DD')}`, fetcher)

  data?.sort((a, b) => b.totalIqd - a.totalIqd)
  const OnClickExportExcel = () => {
    downloadExcel({
      fileName: "expenses",
      sheet: 'expenses',
      tablePayload: {
        header: ExpenseByCategoryLabels,
        body: data.map((item) => {
          const newDt = {}
          newDt['category'] = item[language] || item.en
          newDt['totalIqd'] = item.totalIqd?.toLocaleString()
          newDt['totalUsd'] = item.totalPrice?.toLocaleString()
          return newDt
        }),
      },
    });
  }
  return (
    <div
      className="my-8"

      style={{
        width: isFromPrint ? '100%' : '90%',
        backgroundColor: isFromPrint ? null : Colors.mainWhite,
        padding: 10,
        borderRadius: 5,
        overflow: 'scroll'
      }}
    >
      <div className="flex items-center justify-between" >
        {<h4 className="mb-4 text-lg ">{t('expensesByCategory')}</h4>}
        {!isFromPrint && <div className='cursor-pointer' onClick={OnClickExportExcel} >
          <SiMicrosoftexcel size={28} color='green' />
        </div>}
      </div>
      {<CourseTable labels={ExpenseByCategoryLabels} >
        {data && data.map((dt, index) => {
          return <tr key={index} >
            <td>{dt[language] || dt.en} </td>
            <td>{dt.totalIqd?.toLocaleString()}</td>
            <td>$ {dt.totalPrice?.toLocaleString()}</td>
          </tr>
        })}
      </CourseTable>}
    </div>)
}

export default MonthlyReportScreen;